import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { PositionBuyingPowerCloseResultModel, PositionBuyingPowerModel } from '@mod/data/position-buying-power.model';
import { ObservableService } from '@s/observable.service';
import { UserDataService } from '@s/user-data.service';
import { isNullOrUndefinedOrEmpty } from '@u/utils';

@Component({
  selector: 'app-position-buying-power-popup',
  templateUrl: './position-buying-power-popup.component.html',
  styleUrls: ['./position-buying-power-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PositionBuyingPowerPopupComponent implements OnInit {
  private defaultBuyingPower = 10000;
  private defaultNumberOfPositions = 5;

  private defaultTooltipText = `
    Buying Power - Use available cash for IRA account. Use Buying Power for Margin account.\n
    Positions - Specify how many positions to keep. System will use [Buying Power]/[Positions] as amount available per trade.
  `;

  protected positionSettingForm = this.formBuilder.group({
    buyingPower: [
      this.observableService[this.data.accountBuyingPowerSettingName]?.getValue() ?? '',
      [
        Validators.required,
        Validators.min(1),
        Validators.pattern('^[0-9,.]+'),
        Validators.minLength(4),
        Validators.maxLength(11),
      ],
    ],
    numberOfPositions: [
      this.observableService[this.data.accountNumberOfPositionsSettingName]?.getValue() ?? '',
      [Validators.required, Validators.min(1), Validators.pattern('^[0-9,]+'), Validators.maxLength(2)],
    ],
  });
  protected submitted: boolean;
  protected tooltipText: string = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: Partial<PositionBuyingPowerModel>,
    private dialogRef: MatDialogRef<PositionBuyingPowerPopupComponent, PositionBuyingPowerCloseResultModel>,
    private formBuilder: UntypedFormBuilder,
    private userDataService: UserDataService,
    private observableService: ObservableService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  async ngOnInit(): Promise<void> {
    this.tooltipText = this.data.tooltipText ?? this.defaultTooltipText;

    if (this.data.defaultBuyingPower) {
      this.defaultBuyingPower = this.data.defaultBuyingPower;
    }

    let buyingPower = this.defaultBuyingPower;
    let numberOfPositions = this.defaultNumberOfPositions;

    if (
      !isNullOrUndefinedOrEmpty(this.data.accountBuyingPower) &&
      !isNullOrUndefinedOrEmpty(this.data.accountNumberOfPositions)
    ) {
      buyingPower = this.data.accountBuyingPower;
      numberOfPositions = this.data.accountNumberOfPositions;
    } else {
      const result = await Promise.all([
        this.userDataService.getAsInt(this.data.accountBuyingPowerSettingName),
        this.userDataService.getAsFloat(this.data.accountNumberOfPositionsSettingName),
      ]);

      buyingPower = result[0] ?? buyingPower;
      numberOfPositions = result[1] ?? numberOfPositions;
    }

    this.positionSettingForm.controls.buyingPower.setValue(buyingPower || this.defaultBuyingPower);
    this.positionSettingForm.controls.numberOfPositions.setValue(numberOfPositions || this.defaultNumberOfPositions);

    this.setFocusAndSelectForInput('buying-power-input');
    this.changeDetectorRef.markForCheck();
  }

  protected inputBlur(input: string): void {
    const inputValue = this.positionSettingForm.value[input];

    if (!inputValue) {
      if (input === 'buyingPower') {
        this.positionSettingForm.controls[input].setValue(this.defaultBuyingPower);
      } else if (input === 'numberOfPositions') {
        this.positionSettingForm.controls[input].setValue(this.defaultNumberOfPositions);
      }
    }

    this.changeDetectorRef.markForCheck();
  }

  private setFocusAndSelectForInput(id: string): void {
    const element = document.getElementById(id) as HTMLInputElement;

    if (!element) {
      return;
    }

    element.focus();
    element.select();

    this.changeDetectorRef.markForCheck();
  }

  protected async updateSetting(): Promise<void> {
    if (this.submitted) {
      return;
    }

    this.submitted = true;

    const buyingPowerInput = this.positionSettingForm.controls.buyingPower;
    const numberOfPositionsInput = this.positionSettingForm.controls.numberOfPositions;

    if (buyingPowerInput.invalid) {
      buyingPowerInput.setValue(this.defaultBuyingPower);
    }

    if (numberOfPositionsInput.invalid) {
      numberOfPositionsInput.setValue(this.defaultNumberOfPositions);
    }

    const buyingPower = parseInt(buyingPowerInput.value, 10);
    const numberOfPositions = parseInt(numberOfPositionsInput.value, 10);

    if (this.data.accountBuyingPowerSettingName && this.data.accountNumberOfPositionsSettingName) {
      await this.userDataService.setMultiple([
        { key: this.data.accountBuyingPowerSettingName, value: buyingPower },
        { key: this.data.accountNumberOfPositionsSettingName, value: numberOfPositions },
      ]);
    }

    this.dialogRef.close({ buyingPower, numberOfPositions });
  }
}
