<app-simple-modal-header titleText="Buying Power & Positions" [tooltipText]="tooltipText"></app-simple-modal-header>

<form [formGroup]="positionSettingForm" (ngSubmit)="updateSetting()" class="form-design">
  <mat-dialog-content class="mat-typography">
    <div class="risk-based-position-sizing">
      <div class="risk-input">
        <div class="dollar-input">
          <div class="v-center">
            <label>Buying Power</label>
          </div>
          <div class="input-box w-100">
            <div class="dollar-sign">$</div>
            <input
              id="buying-power-input"
              type="text"
              autofocus
              placeholder="0"
              autocomplete="off"
              formControlName="buyingPower"
              mask="separator.0"
              thousandSeparator=","
              decimalMarker="."
              separatorLimit="100000000"
              (focus)="$event.target.select()"
              (click)="$event.target.select()"
              (blur)="inputBlur('buyingPower')"
            >
          </div>
        </div>
        <div class="icon-input">
          <div class="v-center">
            <label>Positions</label>
          </div>
          <div class="input-box w-100">
            <input
              id="numberOfPositions"
              type="text"
              placeholder="0"
              autocomplete="off"
              step="1"
              min="1"
              mask="separator.0"
              thousandSeparator=","
              decimalMarker="."
              separatorLimit="10"
              formControlName="numberOfPositions"
              (focus)="$event.target.select()"
              (click)="$event.target.select()"
              (blur)="inputBlur('numberOfPositions')"
            >
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="center">
    <button mat-flat-button color="primary" class="fill-btn small-btn">Set</button>
  </mat-dialog-actions>
</form>
